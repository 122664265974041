/*
 * Copyright 2020 Red Hat, Inc. and/or its affiliates.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { ProcessInstanceFilter } from '@kogito-apps/process-list';

const formatSearchWords = (searchWords: string[]) => {
  const tempSearchWordsArray = [];
  searchWords.forEach(word => {
    tempSearchWordsArray.push({ businessKey: { like: word } });
  });
  return tempSearchWordsArray;
};

export const buildProcessListWhereArgument = (
  filters: ProcessInstanceFilter
) => {
  if (filters.businessKey.length === 0) {
    return {
      parentProcessInstanceId: { isNull: true },
      state: { in: filters.status }
    };
  } else {
    return {
      parentProcessInstanceId: { isNull: true },
      state: { in: filters.status },
      or: formatSearchWords(filters.businessKey)
    };
  }
};
